'use client'; // Error components must be Client Components
import {ErrorPage} from '../components/molecules';

export default function Error({
  error,
  reset,
}: {error: Error & {digest?: string}; reset: () => void}) {
  return (
    <ErrorPage
      className={'flex-1'}
      component="app"
      error={error}
      reset={reset}
    />
  );
}
